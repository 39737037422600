/* Base Styles (Desktop and larger) */
.left-side {
  color: #ccd6f6;
  flex: 0 0 350px; /* Fixed width for the left side */
  width: 350px; /* Ensures left side doesn't get too wide */
}

.main-title {
  font-size: 2.1rem; /* Base font size for larger screens */
  line-height: 1.2;
  margin-bottom: 10px;
  /* color: #ccd6f6; */
  color: #fff;
}

.sub-title {
  font-size: 1.2rem;
  line-height: 0.4;
  color: #ccd6f6;
  margin-bottom: 20px;
}

.description {
  font-size: 1.2rem;
  color: #8892b0;
  margin-bottom: 50px;
  line-height: 1.6;
}

/* Laptops (between 1280px and 1024px) */
@media (max-width: 1280px) {
  .left-side {
    width: 300px; /* Reduce width */
    padding-top: 4rem; /* Reduce top padding */
    margin-left: 10px; /* Adjust left margin */
  }

  .main-title {
    font-size: 2rem; /* Slightly reduce font size */
  }

  .sub-title {
    font-size: 1.6rem;
  }

  .description {
    font-size: 1.6rem;
  }
}

/* Tablets (between 1024px and 768px) */
@media (max-width: 1024px) {
  .left-side {
    width: 350px; /* Further reduce width */
  }

  .main-title {
    font-size: 2rem; /* Further reduce font size */
  }

  .sub-title {
    font-size: 1.4rem;
  }

  .description {
    font-size: 1.4rem;
  }
}

/* Mobile (768px and smaller) */
@media (max-width: 768px) {
  .left-side {
    width: 350px; /* Full width */
  }

  .main-title {
    font-size: 1.8rem; /* Smaller font size */
  }

  .sub-title {
    font-size: 1.2rem; /* Smaller font size */
    margin-bottom: 10px; /* Reduce bottom margin */
  }

  .description {
    font-size: 1.2rem; /* Smaller font size */
    margin-bottom: 20px; /* Reduce bottom margin */
  }
}
